.event-form-section {
	.fui-form {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	.fui-row {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	.fui-page-row {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	#fields-kontaktformular-ich-mochte {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	#fields-kontaktformular-your-name {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	.fui-btn {
		@apply font-wotfard-regular;
		@apply text-base;
		@apply bg-primary;
		@apply rounded-full;
		@apply px-8; 
		@apply py-2;

		&:hover {
			@apply bg-seeseitn;
			@apply transform;
			@apply scale-105;
		}
	}

	.fui-submit {
		@apply font-wotfard-regular;
		@apply text-base;
		@apply bg-seeseitn;
		@apply border-none;
	}

	#fields-kontaktformular-message {
		@apply font-wotfard-regular;
		@apply text-base;
	}

	.fui-select {
		@apply font-wotfard-regular;
		@apply text-base;
		@apply text-placeholder;
	}

	.fui-input {
		@apply font-wotfard-regular;
		@apply text-base;
		@apply text-placeholder;
	}

	#fields-kontaktformular-message,
	#fields-kontaktformular-your-name,
	#fields-kontaktformular-ich-mochte1,
	#fields-kontaktformular-email-address {
		border-bottom: solid 2px #243c48;
		@apply rounded-none;
		@apply pb-3;
	}

	#fields-kontaktformular-message {
		height: 170px;
	}


	.fields-kontaktformular-agbs-und-datenschutzbestimmungen {
		border: solid 1px #243c48;
		@apply mt-4;
	}

	.fui-checkbox-label {
		@apply text-card;
		@apply text-body2-dark;
		@apply font-wotfard-regular;
		@apply mt-4;

		a {
			@apply underline;
			@apply text-primary;
		}
	}
}
