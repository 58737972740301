.error-page {
	.error-box {
		@apply -mt-60;

		@screen lg {
			@apply m-0;
		}
	}

	.info-div {
		transform: translate(-50%, -50%);
		@apply top-7/20;
		@apply inset-x-1/2;
	}
}
