.cookie-banner-tab {
	@apply flex;
	@apply fixed;
	@apply cursor-pointer;
	@apply rounded-t-xl;
	@apply left-2;
	bottom: -30px;
	transition: bottom 0.3s ease;
	@apply bg-seeseitn-light;

	&:hover {
		@apply bottom-0;
		@apply bg-seeseitn;
	}

	a {
		@apply px-5;
		@apply py-2;
		@apply text-white;
	}
}

#cookie-banner {
	.cookie-notice {
		@apply w-full;
		@apply max-w-full;
		@apply left-0;
		@apply right-0;
		@apply bottom-0;
		@apply font-wotfard-regular;
		@apply text-body1-dark;
		@apply bg-seeseitn-light;

		a {
			@apply underline;
			@apply text-seeseitn;
		}

		span {
			@apply text-body1-dark;

			a {
				@apply text-seeseitn;
			}
		}

		.cn-body {
			@apply flex;
			@apply p-4;
			@apply w-full;
			@apply flex-col;

			@screen xl {
				@apply flex-row;
				@apply items-center;
				@apply justify-between;
			}

			p {
				@apply text-base;
				@apply w-full;
				@apply max-w-full;

				@screen xl {
					@apply w-3/5;
				}

				span {
					@apply max-w-full;
				}
			}

			.cn-ok {
				@apply mt-2;
				@apply text-body1-dark;

				@screen xl {
					@apply mt-0;
				}

				a {
					@apply text-seeseitn;
				}
			}

			.cn-learn-more {
				@apply text-base;
			}
		}
	}

	.cm-btn-success {
		@apply bg-seeseitn;
		@apply text-white;
		@apply font-wotfard-regular;
	}

	.cm-modal {
		@apply text-body1-dark;

		a {
			@apply text-seeseitn;
		}

		p {
			@apply my-3;
		}

		p,
		label span {
			@apply text-base;
		}

		.cn-decline {
			@apply text-white;
		}

		.cm-header,
		.cm-footer {
			@apply border-opacity-40;
		}

		.cm-body {
			@apply px-4;
			@apply py-6;
		}

		.cm-list-input:checked + .cm-list-label .slider {
			@apply bg-seeseitn;

			&::before {
				@apply bg-white;
			}
		}

		.cm-list-input + .cm-list-label .slider {
			@apply bg-white;

			&::before {
				@apply bg-opacity-20;
			}
		}

		.cm-purpose {
			@apply mb-6;

			&:last-child {
				@apply mb-0;
			}

			.purposes {
				@apply m-0;
			}

			.half-checked {
				@apply bg-seeseitn;
			}
		}

		.cm-services {
			a {
				@apply text-seeseitn;
			}
		}
	}

	.cn-buttons {
		@apply m-0;
		@apply flex;
		@apply w-full;
		@apply justify-between;

		@screen sm {
			@apply w-auto;
			@apply justify-end;
		}
	}

	.cm-btn-success {
		@apply uppercase;
		@apply rounded-full;
		@apply py-2;
		@apply px-4;
		@apply mr-0;
		@apply w-auto;
		letter-spacing: 0.05rem;
	}

	.cn-decline {
		@apply text-body1-dark;
		background: transparent;
		@apply hover:underline;
		@apply p-0;
		@apply text-left;
		@apply w-auto;
		@apply mr-4;
	}

	.cm-btn-accept {
		@apply text-base;
		@apply normal-case;
		background: transparent;

		&:hover {
			background: transparent;
			@apply underline;
		}
	}

	.cm-powered-by {
		@apply hidden;
	}
}
