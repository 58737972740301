.optimized {
	&-image-crop-wrapper {
		position: relative;
		padding-top: 42%;
		overflow: hidden;
	}

	&-image-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		img {
			min-width: 100%;
			min-height: 100%;
		}
	}
}

.main-header {
	.header-image {
		.optimized-image-crop-wrapper {
			padding-top: 70%;
		}
	}

	.header-image-mobile {
		.optimized-image-crop-wrapper {
			padding-top: 100%;
		}
	}
}

.banner-image {
	.optimized-image-crop-wrapper {
		padding-top: 45%;
	}
}

.card-slider-image-optimize {
	.optimized-image-crop-wrapper {
		padding-top: 55%;
	}
}

.image-slider {
	.optimized-image-crop-wrapper {
		padding-top: 55%;
	}
}

.event-slider {
	.optimized-image-crop-wrapper {
		padding-top: 70%;
	}
}

.onlineshop-slider-image-optimize {
	.optimized-image-crop-wrapper {
		padding-top: 70%;
	}
}

.event-container {
	.optimized-image-crop-wrapper {
		padding-top: 65%;
	}
}

.information-section-image-right {
	.optimized-image-crop-wrapper {
		padding-top: 125%;
	}
}

.image-slider {
	.optimized-image-crop-wrapper {
		padding-top: 125%;
	}
}

.header-parking {
	.optimized-image-crop-wrapper {
		padding-top: 130%;
	}
}

.header-subpages {
	.optimized-image-crop-wrapper {
		padding-top: 123%;
	}
}

.pdf-field-optimized-image {
	.optimized-image-crop-wrapper {
		padding-top: 140%;
	}
}

.menu-slider {
	.optimized-image-crop-wrapper {
		padding-top: 140%;
	}
}

.image-text-card {
	.optimized-image-crop-wrapper {
		padding-top: 123%;
	}
}
