@font-face {
	font-family: "wotfard-medium";
	font-display: swap;
	src: url("/src/fonts/wotfard-medium-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "wotfard-regular";
	font-display: swap;
	src: url("/src/fonts/wotfard-regular-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "wotfard-light";
	font-display: swap;
	src: url("/src/fonts/wotfard-light-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Bebas Neue Pro Book";
	font-display: swap;
	src: url("/src/fonts/bebas-neue-pro-book.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
