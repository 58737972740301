.faqs {
	.add-icon {
		transition: all 0.5s ease-in-out;

		&.rotated {
			transform: rotate(45deg);
		}
	}

	.card-slider-1 {
		scrollbar-width: none;
	}

	.card-slider-1::-webkit-scrollbar {
		display: none;
	}

	.swiper-slide {
		@apply w-auto;
	}

	.button-data-topic-id-8 {
		@apply ml-4;

		@screen lg {
			@apply ml-filter-buttons;
		}
	}
}
